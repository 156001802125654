import isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { Fancybox } from "@fancyapps/ui"

function gallery() {	
	const grid = document.querySelector('.gallery__list');
	if(grid) {
		imagesLoaded( grid, function() {
			// init Isotope after all images have loaded
			const iso = new isotope( grid,
				{
					itemSelector: '.gallery__list__item',
					percentPosition: true
				});
			});

		Fancybox.bind("[data-fancybox]", {
		// Your options go here
		});
	
		// window.$ = window.jQuery = require('jquery');
		// const fancybox = require('@fancyapps/fancybox');
		// const fancyboxCSS = require('!style-loader!css-loader!@fancyapps/fancybox/dist/jquery.fancybox.css');
		// $.fancybox.defaults.hash = false;
	
		// $(document).ready(function() {
		// 	$('[data-fancybox="gallery"]').fancybox({
		// 		infobar: false,
		// 		loop: true,
		// 		animationEffect: 'zoom-in-out',
		// 		transitionEffect: 'zoom-in-out',
		// 		buttons: [
		// 			//'zoom',
		// 			//'share',
		// 			//'slideShow',
		// 			//'fullScreen',
		// 			//'download',
		// 			//'thumbs',
		// 			'close'
		// 		],
		// 		idleTime: false,
		// 		fullScreen: {
		// 			autoStart: false
		// 		},
		// 		// caption: function (instance, item) {
		// 		// 	return $(this).next('.list--gallery__caption__container').html();
		// 		// },
		// 		btnTpl: {
		// 			close:
		// 				'<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
		// 				'<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="9.89941" y="32.527" width="32" height="3" transform="rotate(-45 9.89941 32.527)" fill="white" /><rect x="32.5269" y="33.9412" width="31" height="3" transform="rotate(-135 32.5269 33.9412)" fill="white" /></svg>' + "</button>",
		// 			// Arrows
		// 			arrowLeft:
		// 				'<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
		// 				'<div><svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="58" y="58" width="57" height="57" rx="28.5" transform="rotate(-180 58 58)" stroke="white" stroke- width="2"/><path fill- rule="evenodd" clip - rule="evenodd" d = "M44 30.9827L44 27.9827L19.4258 27.9827L23.1022 24L20.8978 21.9651L13.9586 29.4826L20.8978 37L23.1022 34.9651L19.4261 30.9827L44 30.9827Z" fill = "white" /></svg ></div>' + "</button>",
		// 			arrowRight:
		// 				'<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
		// 				'<div><svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="57" height="57" rx="28.5" stroke="white" stroke- width="2"/><path fill- rule="evenodd" clip - rule="evenodd" d = "M15 28.0173L15 31.0173L39.5742 31.0173L35.8978 35L38.1022 37.0348L45.0414 29.5174L38.1022 22L35.8978 24.0348L39.5739 28.0173L15 28.0173Z" fill = "white" /></svg></div>' + "</button>",
		// 		},
		// 	});
		// });
	}
}
export { gallery };
//export default gallery (allows you to call it whatever you want to on import);