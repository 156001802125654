//import "../css/screen.scss";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin.js";
import Headroom from "headroom.js";
import Flickity from "flickity";
import ScrollReveal from "scrollreveal";
import barba from "@barba/core";

gsap.registerPlugin(ScrollToPlugin);

// PEPPER STUFF
import { gallery } from "./pepper/_gallery.js";

// HEADER STUFF
const body = document.querySelector("body"),
  header = document.querySelector("#header"),
  nav = document.querySelector("#nav"),
  navTrigger = document.querySelector("#nav__trigger"),
  navListItems = document.querySelectorAll(".header__nav__list__link"),
  navItems = document.querySelectorAll(".header__nav__item");

// HEADER NAV
function navToggle() {
  body.classList.toggle("header-open");
  navItems.forEach((navItem) => navItem.classList.toggle("header-open__nav"));
}
function navToggleClose() {
  body.classList.remove("header-open");
  navItems.forEach((navItem) => navItem.classList.remove("header-open__nav"));
}

navTrigger.addEventListener("click", navToggle);
navListItems.forEach((navListItem) => navListItem.addEventListener("click", navToggle));

// HEADROOM
var options = {
  offset: 50,
  classes: {
    pinned: "is-showing",
    unpinned: "is-not-showing",
    top: "is-top",
    notTop: "is-not-top",
  },
}
var headroom = new Headroom(header, options);

headroom.init();

/* date/time picker */
function datePicker() {
  if (document.querySelector(".date")) {
    const timeParent = document.querySelector(".time").parentElement;
    const dateParent = document.querySelector(".date").parentElement;
    timeParent.classList.add("booking__row");
    dateParent.classList.add("booking__row");
    $(".time input").pickatime({
      formatSubmit: "H:i",
      min: [10, 0],
      max: [19, 0],
    });
    $(".date input").pickadate({
      formatSubmit: "yyyy/mm/dd",
      firstDay: 1,
      disable: [7],
    });
  }
}
/* keyboard tabbing */

function keyboardFocus(e) {
  if (e.keyCode === 9) {
    // Tab key
    document.documentElement.classList.add("keyboard-focus");
  }

  document.removeEventListener("keydown", keyboardFocus, false);
}

document.documentElement.classList.remove("no-js");
document.addEventListener("keydown", keyboardFocus, false);

// CAROUSEL STUFF
// THIS MAY FAIL IF THERE'S MORE THAN ONE ON A PAGE...
function initCarousels() {
  var carousels = document.querySelectorAll(".carousel");
  carousels.forEach(function (carousel) {
    var flkty = new Flickity(carousel, {
      prevNextButtons: false,
      pageDots: true,
      wrapAround: true,
      groupCells: true,
    });
  });
}

// EFFECTS AND STUFF
function taliareLetters() {
  if (document.querySelectorAll(".pageheader__logo")) {
    const taliareLetters = document.querySelectorAll(
      ".pageheader__logo .letter"
    );
    if (taliareLetters) {
      gsap.set(taliareLetters, { autoAlpha: 0, y: 10 });
      gsap.to(taliareLetters, {
        delay: 2,
        duration: 0.8,
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: "power4.out",
      });
    }
  }
}

const fadeIn = {
  reset: false,
  viewFactor: 0.2,
  distance: "20px",
  afterReveal: function (e) {
    const title = e.querySelector("h3");
    gsap.to(title, { duration: 0.6, opacity: 1, x: 0 });
  },
};

function scrollRevealEffects() {
  ScrollReveal().reveal(".fade-in", fadeIn);
  ScrollReveal().reveal(".block__cta");
}

function scroller(target, offset) {
  gsap.to(window, {
    duration: 0.5,
    scrollTo: {
      y: target,
      offsetY: offset,
      autoKill: true,
    },
    ease: "power2",
  });
}

const scrollItems = document.querySelectorAll(".scroll");
scrollItems.forEach((scrollItem) =>
  scrollItem.addEventListener("click", function (e) {
    const target = this.getAttribute("href");
    e.preventDefault();
    scroller(target, 0);
    // var id = $(this).attr('href');

    // if($(id).length > 0) {
    // 	e.preventDefault();
    // 	scroll(id,0);
    // }
  })
);

// BARBA STUFF
// 1	before	Before everything
// 2	beforeLeave	Before leave transition
// 3	leave	Current page leave transition
// 4	afterLeave	After leave transition
// 5	beforeEnter	Before enter transition and after adding next container (runs on refresh)
// 6	enter	Next page enter transition
// 7	afterEnter	After enter transition (runs on refresh)
// 8	after	After everything

// define a global hook
// barba.hooks.leave((data) => {

// });

barba.hooks.beforeLeave((data) => {
  // console.log("beforeLeave");
  // scroller("#top", 0);
  // scrollTo(0, { duration: 0 });
});

barba.hooks.leave((data) => {
  // console.log("leave");
  ScrollReveal().destroy();
  navToggleClose();
});

barba.hooks.beforeEnter((data) => {
  // console.log("beforeEnter");
  taliareLetters();
  initCarousels();
  scrollRevealEffects();
  gallery();
  datePicker();
});

barba.hooks.enter((data) => {
  // console.log("enter");
});

barba.hooks.afterEnter((data) => {
  // console.log("afterEnter");
});

// basic default transition (with no rules and minimal hooks)
barba.init({
  debug: false,
  prefetchIgnore: true,
  prevent: ({ el }) => el.classList && el.classList.contains("no-trans"),
  //timeout: 100000,
  transitions: [
    // DEFAULT FADE ----------------------------------------------------------------------------------------------------
    {
      name: "fade",
      leave({ current, next, trigger }) {
        // do something with `current.container` for your leave transition then return a promise or use `this.async()`
        const cc = current.container,
          nc = next.container,
          done = this.async(),
          hash = window.location.href.indexOf("#") != -1,
          hashScroll = window.location.hash.split("#")[1],
          outTransition = new gsap.timeline();

        outTransition
          //.to('.pageheader__title', .5, {y:-50, autoAlpha:0})
          .to(cc, {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: function () {
              if (hash) {
                scroller(`#${hashScroll}`, 0);
              } else {
                scrollTo(0, { duration: 0 });
              }
              done();
            },
          });
      },
      enter({ current, next, trigger }) {
        // do something with `next.container` for your enter transition then return a promise or use `this.async()`
        const cc = current.container,
          nc = next.container,
          done = this.async();

        gsap.set(cc, { display: "none" });
        gsap.set(nc, { visibility: "visible", opacity: 0 });

        const namespaceTo = nc.getAttribute("data-namespace");
        const taliareText = document.querySelectorAll(".header__logo__text");

        if (namespaceTo == "home") {
          //gsap.set(taliareText, { autoAlpha:0, y:10 });
          gsap.to(taliareText, { duration: 0.2, autoAlpha: 0 });
        } else {
          gsap.to(taliareText, { duration: 0.5, autoAlpha: 1 });
        }

        var inTransition = new gsap.timeline();
        inTransition
          //.to(window, 1,{scrollTo:{y:offset}})
          .to(nc, 0.5, {
            autoAlpha: 1,
            duration: 0.5,
            onComplete: function () {
              done();
            },
          });
      },
    },
  ],
});
